import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Description } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Description",
  "componentId": "description",
  "description": "Descriptions are used to provide additional information about an object with its title.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Please visit the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/typography"
        }}>{`Typography`}</a></strong>{` page for more information on using the Description component.`}</p>
    <p><strong parentName="p">{`Documentation in progress---------------------`}</strong></p>
    <h2><strong parentName="h2">{`Variants`}</strong></h2>
    <PatternExample example={<Description mdxType="Description">Example description text</Description>} title="Default" mdxType="PatternExample" />
    <PatternExample example={<Description variant="condensed" mdxType="Description">Example description text</Description>} title="Condensed" mdxType="PatternExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      